import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from "@/api/common.js"
import moment from 'moment'

class Distribution{

    /**
     * 统计
     * @param {function} fn 
     */
    disStatistics(fn){
        $post('disStatistics').then(res=> fn(res.data))
    }

    /**
     * 获取分销用户列表数据
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getDisUser(page,limit,extra,fn){
        let param = { page,limit }
        if( extra) {
            if( extra.name ) param.name = extra.name
            if( extra.lv_id ) param.lv_id = extra.lv_id
            if( extra.mobile ) param.mobile = extra.mobile
            if( extra.status ) param.status = extra.status
        }
        common.getDataList("distriList",param).then(res=>fn(res))
    }

    /**
     * 分销商审核
     * @param {number}} user_id 
     * @param {number} type 通过2 拒绝3
     * @param {function} fn 
     */
    auditDis(user_id,type,fn){
        let title = "确认通过该申请吗？"
        if( type == 3 ) title="确认驳回该申请吗？"
        tool.confirm(title).then(()=>{
            $post('ApplyDisUser',{user_id,type}).then(res=>{
                tool.message("操作成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 删除分销商
     * @param {number} id 
     * @param {function} fn 
     */
    deleteDis(id,fn){
        tool.confirm("确认要删除该分销商的信息吗？",()=>{
            $post('delDisUser',{user_id:id}).then(res=>{
                tool.message("删除成功")
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 修改分销用户上下级关系
     * @param {string} sence update 修改 clear清空
     * @param {number} uid   目标uid
     * @param {function} fn 
     */
    updateDisUser(sence,param,uid,fn){
        let { update_type,user_id ,one_distributor,two_distributor} = param
        let title = `确认修改${update_type == 1 ?'一':'二'}级分销商为选择的UID为【${uid}】的用户吗？`
        let data = {user_id,one_distributor,two_distributor}
        if( sence == 'update' ){
            if( update_type == 1 ) data.one_distributor = uid
            if( update_type == 2 ) data.two_distributor = uid
        }
        if( sence == 'clear' ){
            title="确认清空上级关系吗?"
            if( update_type == 1 ) data.one_distributor = 'clear'
			if( update_type == 2 ) data.two_distributor = 'clear'
        }
        tool.confirm(title).then(()=>{
            $post('updateDisUser', data).then(res=>{
        		tool.message('操作成功')
                fn(res.data)
        	})
        }).catch(()=>{})
    }
    
    /**
     * 获取分销商团队用户
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getDisUserTeam(page,limit,extra,fn){
        let param = { page,limit }
        if( extra) {
            if( extra.user_id ) param.user_id = extra.user_id
            if( extra.type ) param.type = extra.type
        }
        common.getDataList("MyDisTeam",param).then(res=>fn(res))
    }

    /**
     * 获取推广用户
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getDisPromoteUser(page,limit,extra,fn){
        let param = { page,limit }
        if( extra ){
            if( extra.key ) param.key = extra.key
            if( extra.type ) param.type = extra.type
            if( extra.user_id ) param.user_id = extra.user_id
            if( extra.user_type ) param.user_type = extra.user_type
            if( extra.time.length > 0 ){
                param.start_time = moment(extra.time[0]).format("YYYY-MM-DD")
                param.end_time = moment(extra.time[1]).format("YYYY-MM-DD")
            }
            if( extra.time_type !=1 ){
                param.start_time = extra.start_time
                param.end_time = extra.end_time
            }
        }
        common.getDataList("getPromoterList",param).then(res=>fn(res))
    }

     /**
     * 获取推广订单
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getDisPromoteOrder(page,limit,extra,fn){
        let param = { page,limit}
        if( extra ){
            if( extra.key ) param.key = extra.key
            if( extra.type ) param.type = extra.type
            if( extra.user_id ) param.user_id = extra.user_id
            if( extra.user_type ) param.user_type = extra.user_type
            if( extra.time.length > 0 ){
                param.start_time = moment(extra.time[0]).format("YYYY-MM-DD")
                param.end_time = moment(extra.time[1]).format("YYYY-MM-DD")
            }
            if( extra.time_type !=1 ){
                param.start_time = extra.start_time
                param.end_time = extra.end_time
            }
            common.getDataList("getPromoterOrder",param).then(res=>fn(res))
        }
    }


    /**
     * 获取分销订单列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getDisOrder(page,limit,extra,fn){
        let param = { page,limit}
        if( extra.type ) param.type = extra.type
        if( extra.name ) param.name = extra.name
        if( extra.mobile ) param.mobile = extra.mobile
        if( extra.time.length >0 ) {
            param.start_time = moment(search.time[0])
            param.end_time = moment(search.time[1])
        }
        common.getDataList("DisOrderList",param).then(res=>{
            fn(res)
        })
    }

    /**
     * 发放佣金
     * @param {number} id 订单id
     * @param {function} fn 
     */
    setDisSendPrice(id,fn){
        tool.confirm("确认发放订单的佣金吗？").then(()=>{
            $post("disOrderCommissionPayment",{id}).then(res=>{
                tool.message("佣金发放成功")
                fn(res.data)
            }).catch(()=>{})
        }).catch(()=>{})
    }

    /**
     * 获取分销配置
     * @param {Array} keys 
     * @param {function} fn 
     */
    getDisConfig(keys,fn){
        $post('getDisConfig').then(res=>{
            if( keys && res.data){
                let data = tool.filterForm(keys,res.data)
                fn(data)
                return
            }
            fn(res.data)
		})
    }

    /**
     * 保存分销配置
     * @param {object} param 
     */
    saveDisConfig(param){
        if( typeof param.withdrawal_method == 'object' ) {
            param.withdrawal_method = param.withdrawal_method.join(',');
        }
        //判断该设置的唯一id是否存在，不存在的话要把它删掉，否则会保存失败
        if( param.program_id == '' || !param.program_id){
            delete param.program_id
        }
        $post('setDisConfig',param).then(()=>tool.message('保存成功'))
    }

    /**
     * 获取分销等级信息
     * @param {number} page 
     * @param {number} limit 
     * @param {object}} extra 
     * @param {function} fn 
     */
    getDisLevel(fn){
        $post("getDisLvList").then(res=>fn(res.data))
    }

    /**
     * 新增或编辑分销商等级
     * @param {number} param 
     * @param {function} fn 
     */
    addOrEditLevel(param,fn){
        if( !param.id ) delete param.id
        $post("DisLvConfig",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 设置分销用户的分销等级
     * @param {number} user_id 
     * @param {number} level_id 
     * @param {function} fn 
     */
    setDisUserLevel(user_id,level_id,fn){
        if( !level_id || level_id == "" ){
            tool.message("请选择分销等级","warning")
            return
        }
        $post("setDisLv",{user_id,id:level_id}).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 获取分销商提现
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getDisWithdraw(page,limit,extra,fn){
        let param = { page,limit }
        if( extra ){
            if( extra.name ) param.name = extra.name
            if( extra.status ) param.status = extra.status
            if( extra.account ) param.account = extra.account
        }
        common.getDataList("disWithLog",param).then(res=>fn(res))
    }

    /**
     * 提现审核
     * @param {number} id 
     * @param {number} status 
     * @param {function} fn 
     */
    handleDisWithdraw(id,status,fn){
        let tip = status == 2 ?'同意':(status == 4 ? '手动打款并同意':'驳回')
        tool.confirm(`确认${tip}该提现申请吗？`).then(()=>{
            $post('changeSomethingByWith',{ id,status} ).then(res=>{
                tool.message("操作成功");
                fn(res.data)
            })
        }).catch(()=>{})
    }

    /**
     * 获取分销商等级规则
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getDisRule(page,limit,fn){
        let param = { page,limit }
        common.getDataList("getDisRuleList",param).then(res=>fn(res))
    }

    /**
     * 新增或编辑分销商等级规则
     * @param {number} param 
     * @param {function} fn 
     */
    addOrEditDisRule(param,fn){
        if( !param.id ) delete param.id
        $post("createOrUpdateDisRule",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 获取分销用户的分销金额明细
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getDisMoneyLog(page,limit,extra,fn){
        let param = { page,limit }
        if( extra && extra.user_id ) param.user_id = extra.user_id
        common.getDataList("getDisMoneyLog",param).then(res=>fn(res))
    }
}

const distributionModel = new Distribution()

export default distributionModel